import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import IconsSubmit from '../../components/styled/IconsSubmit';
import { GlobalContext } from '../../context/GlobalContext';
import Popup from "../../popup/Popup"

const indianLanguages = [
    'English',
    'Hindi',
    'Telugu',
    'Tamil',
    'Bengali',
    'Malayalam',
    'Kannada',
    'Gujarati',
    'Punjabi',
    'Marathi',
    'Urdu',
    'Arabic',
];

const TasksS1 = ({ setImgListName, setFolderDirName, setTemplateSegmenation }) => {
    const { API_URL, showToast } = useContext(GlobalContext);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFileType, setSelectedFileType] = useState('image');
    const [selectedModalityType, setSelectedModalityType] = useState('printed');
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [selectedTempalte, setSelectedTempalte] = useState("");
    const [ocrEnabled, setOcrEnabled] = useState(false);
    const [mtEnabled, setMtEnabled] = useState(false);
    const [templateEnabled, setTemplateEnabled] = useState(false);

    const [OCREngine, setOCREngine] = useState(null);
    const [MTEngine, setMTEngine] = useState(null);
    const [languageMT, setLanguageMT] = useState("");

    const [message, setMessage] = useState('');
    const [alertMessage, setAlertMessage] = useState(false);

    const storedData = localStorage.getItem('userObjectArray');
    const authTokenAccess = storedData ? JSON.parse(storedData)[0]?.auth : null

    // console.log("authTokenAccess response:", authTokenAccess)
    useEffect(() => {
        const getSettingsData = async () => {
            try {
                const response = await axios.post(`${API_URL}/api/getSettings`, {"token": authTokenAccess}, {
                    headers: {
                        'Content-Type': 'application/json',
                        // 'Authorization': `Bearer ${authTokenAccess}`,
                    },
                });
                console.log("(response.data.data[0].mteng);", response.data.data[0])
                if (response.data.data.length > 0) {
                    setMTEngine(response.data.data[0].mteng);
                    setOCREngine(response.data.data[0].ocreng);
                } else {
                    setMTEngine("IIITH-ONEMT");
                    setOCREngine("IIITH-OCR");
                }
            } catch (error) {
                console.log(error);
            }
        };
        getSettingsData();
        // eslint-disable-next-line
    }, []);


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };

    const handleLanguageChange = (e) => {
        setSelectedLanguage(e.target.value);
    };

    const handleLanguageMT = (e) => {
        setLanguageMT(e.target.value);
    };

    const handleOcrToggle = () => {
        setOcrEnabled(!ocrEnabled);
    };

    const handleMtToggle = () => {
        setMtEnabled(!mtEnabled);

    };
    // eslint-disable-next-line
    const handleLanguageOCR = (e) => {
        setOCREngine(e.target.value);
    };

    const handleSelectTemplate = (e) => {
        setSelectedTempalte(e.target.value);
    };

    // eslint-disable-next-line
    const handleTemplateToggle = () => {
        setTemplateEnabled(!templateEnabled);

        if (!templateEnabled) {
            setSelectedTempalte(null);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setImgListName([]);

        if (!selectedFile) {
            showToast('error', 'Select source language, file and OCR Engine...', 'top', 10);
            return { "message": "No select file" };
        } else if (selectedFile && selectedFile.size > 50 * 1024 * 1024) {
            showToast('error', 'File size exceeds 50MB limit', 'top', 10);
            return;
        } else if (!selectedModalityType || !selectedLanguage || !OCREngine) {
            showToast('error', 'Select Modality, Source Language, OCR Engine', 'top', 10);
            return;
        }

        setIsLoading(true);

        const formData = new FormData();
        formData.append('file', selectedFile, selectedFile.name);
        formData.append('sourceLanguage', selectedLanguage);
        formData.append('targetLanguage', languageMT);
        formData.append('ocrEnabled', ocrEnabled);
        formData.append('mtEnabled', mtEnabled);
        formData.append('OCREngine', OCREngine);
        formData.append('MTEngine', MTEngine);
        formData.append('templateEnabled', templateEnabled);
        formData.append('selectedTempalte', selectedTempalte);
        formData.append('selectedModalityType', selectedModalityType);
        formData.append('token', authTokenAccess);

        try {
            const response = await axios.post(`${API_URL}/api/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    // 'Authorization': `Bearer ${authTokenAccess}`,
                },
            });
            setFolderDirName(response.data.folderDir)
            setImgListName(response.data.filename)
            setTemplateSegmenation(response.data.templateSegment)

            if (response.data.message && response.data.message.length > 0) {
                setMessage(response.data.message);
                setAlertMessage(true);
            } else if (response.data.message === null) {
                setAlertMessage(false);
            };

        } catch (error) {
            setAlertMessage(true);
            setMessage(error.message);
            console.error('Error in api/upload:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div>{alertMessage ? (<Popup setOpenModal={setAlertMessage} message={message} />) : (null)}</div>
            <div className="flex flex-wrap border-t-2 border-blue-100 mt-0.5 justify-between">
                <div className="w-full sm:w-12">
                    <h1 className="text-3xl text-center h-10 rounded-full text-slate-900 bg-blue-300 font-bold mb-4 border border-blue-500 -ml-5 -m-1 w-10 border-t-0 -mt-0.5">1</h1>
                </div>
                <div className="w-full mr-2 sm:w-1/5">
                    <div className="bg-blue-300 font-bold py-2 pl-4 mb-2">1.1 Source Documents</div>
                    <div>
                        <select
                            className="w-full hover-bg-gray-100 p-2 border bg-slate-100 border-slate-300 rounded focus:outline-none focus:ring focus:ring-blue-300"
                            name="language"
                            value={selectedLanguage}
                            onChange={handleLanguageChange}
                        >
                            <option value="" disabled>Select Language</option>
                            {indianLanguages
                                .slice()
                                .sort((a, b) => a.localeCompare(b))
                                .map((language, index) => (
                                    <option key={index} value={language.toLowerCase()}>
                                        {language}
                                    </option>
                                ))}
                        </select>
                    </div>

                    <div className='mt-2'>
                        <select
                            className='w-full hover-bg-gray-100 p-2 border bg-slate-100 border-slate-300 rounded focus:outline-none focus:ring focus:ring-blue-300'
                            value={selectedTempalte || ''}
                            onChange={handleSelectTemplate}
                        >
                            <optgroup label="Select Layout">
                            <option value="" disabled selected>Select Layout / Template</option>
                                <option value="c_l_1">Block Layout</option>
                                <option value="c_l_2">2 Columns Layout</option>
                                <option value="c_l_3">3 Columns Layout</option>
                            </optgroup>
                            <hr className='font-bold text-gray-300 py-3' />
                            <optgroup label="Select Templates">
                                <option value="birth-certificate">Birth Certificate</option>
                                <option value="migration-certificate">Migration Certificate</option>
                                <option value="mss_cert_1">Mission Sikshan Samvaad</option>
                                <option value="c_1">Custom Template 1</option>
                                <option value="c_2">Custom Template 2</option>
                            </optgroup>
                        </select>
                    </div>
                </div>


                <div className="w-full mr-2 sm:w-1/4">
                    <div>
                        <div className="bg-blue-300 font-bold py-2 pl-4 mb-2">1.2 Select Image or PDF</div>
                        <div className='mb-1'>
                            <input
                                type="radio"
                                className='mr-2'
                                id="image"
                                name="fileType"
                                value="image"
                                checked={selectedFileType === 'image'}
                                onChange={() => setSelectedFileType('image')}
                            />
                            <label className='mr-10' htmlFor="image">Image</label>

                            <input
                                type="radio"
                                className='mr-2'
                                id="pdf"
                                name="fileType"
                                value="pdf"
                                checked={selectedFileType === 'pdf'}
                                onChange={() => setSelectedFileType('pdf')}
                            />
                            <label htmlFor="pdf">PDF</label>
                        </div>

                        <div>
                            <input
                                type="file"
                                accept={selectedFileType === 'image' ? '.png, .jpeg, .jpg' : '.pdf'}
                                onChange={handleFileChange}
                                className="w-full border border-gray-300 p-2 rounded mr-10 hover:bg-gray-100"
                            />

                        </div>
                        <div className='mt-2'>
                            <input
                                type="radio"
                                className='mr-2'
                                id="printed"
                                name="modalityType"
                                value="printed"
                                checked={selectedModalityType === 'printed'}
                                onChange={() => setSelectedModalityType('printed')}
                            />
                            <label className='mr-10' htmlFor="image">Printed</label>

                            <input
                                type="radio"
                                className='mr-2'
                                id="handwritten"
                                name="modalityType"
                                value="handwritten"
                                checked={selectedModalityType === 'handwritten'}
                                onChange={() => setSelectedModalityType('handwritten')}
                            />
                            <label htmlFor="pdf">Handwritten</label>
                        </div>
                    </div>
                    {selectedFile ? (
                        <p>
                            <Link to={URL.createObjectURL(selectedFile)} className="text-blue-500" target="_blank">
                                {selectedFile.name}
                            </Link>
                        </p>
                    ) : (
                        ''
                    )}
                </div>
                <div className="w-full mr-2 sm:w-1/4">
                    <div>
                        <div className="bg-blue-300 font-bold py-2 pl-4 mb-2">1.3 Task</div>
                        <div>
                            <label className="block">
                                <input
                                    type="checkbox"
                                    className="mr-2"
                                    checked={ocrEnabled}
                                    onChange={handleOcrToggle}
                                />
                                OCR Engine{" "}
                                {ocrEnabled ? <span className="ml-2 bg-blue-300 px-2 py-1">{OCREngine}</span> : ''}

                            </label>

                            <label className="block mt-2">
                                <input
                                    type="checkbox"
                                    className="mr-2"
                                    checked={mtEnabled}
                                    onChange={handleMtToggle}
                                />
                                MT Engine {" "}
                                {mtEnabled ? <span className="ml-5 bg-blue-300 px-2 py-1">{MTEngine}</span> : ""}
                            </label>
                            {mtEnabled && (
                                <div className='mb-1 mt-2'>
                                    <label htmlFor="languageSelect">Target Language:</label>
                                    <select
                                        id="languageSelect"
                                        className='hover-bg-gray-100 border bg-slate-100 border-slate-300 w-1/2 h-7 focus:outline-none focus:ring focus:ring-blue-300'
                                        value={languageMT}
                                        onChange={handleLanguageMT}
                                    >
                                        <option value="" disabled>Select Language</option>
                                        {indianLanguages
                                            .slice() // Create a copy to avoid modifying the original array
                                            .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
                                            .map((language, index) => (
                                                <option key={index} value={language.toLowerCase()}>
                                                    {language}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="w-full mr-2 sm:w-1/5">
                    <div>
                        <div className="bg-blue-300 font-bold py-2 pl-4 mb-2">1.4 Action</div>
                        <div className='flex flex-wrap text-center justify-between'>
                            <div className=''>
                                <button
                                    className="px-2 py-2 mt-2 bg-blue-300 font-semibold text-gray-900 shadow-md ring-1 ring-inset ring-blue-400 hover:bg-blue-100 rounded-full text-start"
                                    onClick={handleSubmit}
                                >
                                    {isLoading ? <IconsSubmit /> : 'Submit Now'}
                                </button>
                            </div>
                            <div>
                                <button
                                    className="px-2 py-2 mt-2 bg-blue-300 font-semibold text-gray-900 shadow-md ring-1 ring-inset ring-blue-400 hover:bg-blue-100 rounded-full"
                                >
                                    Batch
                                    {/* {isLoading ? <IconsSubmit /> : 'Batch'} */}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default TasksS1;
