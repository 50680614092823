import Tasks from '../components/dashboard/Tasks';

const Dashboard = () => {
  return (
    <>
      <div className='mr-20 ml-20 mb-5' >
        <Tasks />
      </div>

    </>
  );
};

export default Dashboard;
