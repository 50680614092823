import React, { useState, useContext } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import axios from 'axios';
// import { Link } from 'react-router-dom';
import ImageViewer from '../../components/ImageViewer';
import DashboardView from "../dashboard/DashboardView"
import SearchArchiveDocuments from "./SearchArchiveDocuments"

const Tasks = ({ data, text, images, setImages, viewText, setViewText }) => {
    const { API_URL } = useContext(GlobalContext);
    const [isLoading, setIsLoading] = useState({})
    const [selectedImage, setSelectedImage] = useState('');
    const [imageName, setImageName] = useState([]);
    const [folderDir, setFolderDir] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editedText, setEditedText] = useState('');
    const [translatedText, setTranslatedText] = useState([])
    // eslint-disable-next-line
    const [textViewSave, setTextViewSave] = useState([]);
    // eslint-disable-next-line
    const [viewedRowIndex, setViewedRowIndex] = useState('')
    const [message, setMessage] = useState('')
    const [isLoading11, setIsLoading11] = useState(false);
    
    const storedData = localStorage.getItem('userObjectArray');
    const authTokenAccess = storedData ? JSON.parse(storedData)[0]?.auth : null

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        setIsEditing(false);
        setTextViewSave(editedText); // Call the onSave function with the edited text
    };

    const file = new Blob([editedText], { type: 'text/plain' });
    const fileUrl = URL.createObjectURL(file);

    let fileName = "";

    if (typeof imageName === 'string') {
        fileName = imageName.split('.')[0] + '.txt';
    } else {
        console.error('selectedImage is not a string...');
    }

    const formattedData = translatedText.map(row => row.join('\t')).join('\n');
    const filenameEngHin = new Blob([formattedData], { type: 'text/plain' });
    const fileUrlEngHin = URL.createObjectURL(filenameEngHin);

    const nanoTimestamp = new Date().getTime() * 1000;
    const filename_en_hin = `${nanoTimestamp}.txt`;

    if (fileUrlEngHin) {
        // Rest of your code that uses fileName and fileUrl
        // For example, you can create a download link or perform other actions
        console.log("File name:", filename_en_hin);
    } else {
        // Handle the case where fileUrl is null
        console.error('fileUrl is null.');
    }


    const handleSubmit = async (event, taskid, pageid, index) => {
        event.preventDefault();
        setIsLoading({ ...isLoading, [taskid]: true });
        setSelectedImage('')

        try {
            const response = await axios.post(`${API_URL}/api/archive`, { "taskid": taskid, "pageid": pageid, "token": authTokenAccess }, {
                headers: {
                    'Content-Type': 'application/json',
                    //Authorization: `Bearer ${authTokenAccess}`,
                },
            });
            if (response.data.imageName.length > 0 && response.status === 200) {
                setImages(response.data.imageName);
                setFolderDir(response.data.folderDir);
                setImageName([])
                setViewText('')
                console.log("archive response:", response.data.imageName);
            } else if (response.data.imageName.length === 0) {
                alert("No record found...");
            } else if (response.data.message) {
                alert('message: ' + message);
            }

        } catch (error) {
            alert('message: ' + error.message);
            console.error('Error Connection:', error);
            // Handle the error, e.g., display an error message to the user.
        } finally {
            console.log("Finally....");
            setIsLoading({ ...isLoading, [taskid]: false }); // Set loading to false for the specific row
        }
    };

    const handleTextView = async (event, taskname, index) => {
        event.preventDefault();
        setViewText('')

        setIsLoading({ ...isLoading, [taskname]: true }); // Set loading to true for the specific row
        setViewedRowIndex(index);
        const formData = new FormData();
        formData.append('taskname', taskname);
        formData.append('token', authTokenAccess);

        setIsLoading11(true)

        try {
            const response = await axios.post(`${API_URL}/api/archiveTextView`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    // Authorization: `Bearer ${authTokenAccess}`,
                },
            });

            console.log("response Tasks:", response.data);

            if (response.data.filename.length > 0 && response.status === 200) {
                setImageName(response.data.filename);
                setViewText(response.data.text);
                setEditedText(response.data.text);
                setTranslatedText(response.data.data);
                setFolderDir(response.data.folderDir);
            } else if (response.data.message && response.status === 200) {
                setMessage(response.data.message)
                alert('message: ' + message);
            }
        } catch (error) {
            alert("Error" + error.message)
            console.error('Error Connection:', error);
        } finally {
            console.log("Finally....");
            setIsLoading11(false)
            setIsLoading({ ...isLoading, [taskname]: false });
        }
    };


    return (
        <>
            <div className='mx-20'>
                {Array.isArray(data) && data.length > 0 ?
                    (<SearchArchiveDocuments data={data} handleSubmit={handleSubmit} isLoading={isLoading} />
                    ) : (
                        <table className="w-full mt-2">
                        <tr>
                            <td className="border border-gray-300 py-3 text-xl text-center" colSpan="3">No Task available</td>
                        </tr>
                    </table>
                    )}

                {images && images.length > 0 ? (
                    <div className='mt-5'>
                        <ImageViewer imageName={images} selectedImage={selectedImage} setSelectedImage={setSelectedImage} folderDir={folderDir} />
                    </div>
                ) : ("")
                }

                {images && images.length > 0 ? (
                    <div className='text-center'>
                        {selectedImage &&
                            <button
                                className="text-3xl px-6 py-3 mt-2 bg-blue-300 font-semibold text-gray-900 shadow-md ring-1 ring-inset ring-blue-300 hover:bg-blue-100 rounded ml-auto text"
                                onClick={(e) => { handleTextView(e, selectedImage) }}
                            >
                                {isLoading11 ? (
                                    "Processing, Please wait..."
                                ) : (
                                    'Text View'
                                )}
                            </button>
                        }
                    </div>
                ) : ("")
                }

                {/* view tasks from table */}
                {
                    viewText.length > 0 ? (
                        <div>
                            <div>
                                <DashboardView
                                    API_URL={API_URL}
                                    imageName={selectedImage}
                                    folderDir={folderDir}
                                    editedText={editedText}
                                    isEditing={isEditing}
                                    setEditedText={setEditedText}
                                    handleEditClick={handleEditClick}
                                    handleSaveClick={handleSaveClick}
                                    fileName={fileName}
                                    fileUrl={fileUrl}
                                    searchText={text}
                                />
                            </div>
                        </div>
                    ) : ""
                }
            </div >
        </>
    );
};

export default Tasks;
