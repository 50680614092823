import "./Popup.css";

function Popup({ setOpenModal, message }) {

  return (
    <div className="popUpBackground">
      <div className="modalContainerPopup">
        <div class="flex flex-nowrap gap-4">
          <div class="w-full">{message}</div>
          <div class="w-[20px]">
            <button className="rounded-full text-md" onClick={() => { setOpenModal(false) }}>X</button>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Popup;
