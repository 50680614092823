import React, { useState, useEffect, useRef, useContext } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import axios from 'axios';
import Popup from '../../popup/Popup';
import IconsSubmit from '../../components/styled/IconsSubmit';

const Archieve = ({ setData, text, setText, setImages, setViewText }) => {
    const { API_URL } = useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const storedData = localStorage.getItem('userObjectArray');
    const authTokenAccess = storedData ? JSON.parse(storedData)[0]?.auth : null

    const textAreaRef = useRef(null);

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = 'auto';
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
    }, [text]);

    const handleSearch = async () => {
        if (text.trim() === '') {
            setErrorMessage('Please enter text to search the archive.');
            setTimeout(() => {
              setErrorMessage('');
            }, 3000);
            return;
          }
        
        setData([])
        setImages([])
        setViewText('')
        
        const JSONData = {
            text: text,
            token: authTokenAccess
        };

        setIsLoading(true)
        try {
            const response = await axios.post(`${API_URL}/api/search`, JSONData, {
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${authTokenAccess}`,
                },
            });

            if (response.status === 200) {
                console.log("archive search response: ", response.data)
                if (response.data.data.length === 0) {
                    setErrorMessage('Searching text not found in the Archive');
                    setTimeout(() => {
                        setErrorMessage(false);
                      }, 2000);
                } else {
                    setData(response.data.data);
                }
            }
        } catch (error) {
            setErrorMessage(`Error: ${error.message}`);
            console.error('Error searching database:', error);
        } finally {
            setIsLoading(false)
        }
    };

// eslint-disable-next-line
    const removeErrorMessage = () => {
        setErrorMessage(null);
    };

    return (
        <>
            
            {errorMessage ? (<Popup setOpenModal={removeErrorMessage} message={errorMessage} />) : (null)}
        
            <div className="">
                <div className="flex space-x-4">
                    <div className="w-1/2">
                        <textarea
                            ref={textAreaRef}
                            type="text"
                            className="w-full border border-blue-300 hover:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300"
                            placeholder="Enter text......"
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                        />
                    </div>
                    <div className="">
                        <button className="text-xl px-6 py-2 bg-blue-300 font-semibold text-gray-900 shadow-md ring-1 ring-inset ring-blue-300 hover:bg-blue-100 rounded ml-auto" onClick={handleSearch}>
                        {isLoading ? <IconsSubmit /> : 'Search'}
                        </button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Archieve