import { useContext } from 'react'
import { GlobalContext } from '../context/GlobalContext';

const ImageViewer = ({ imageName, selectedImage, setSelectedImage, folderDir }) => {

    const { API_URL } = useContext(GlobalContext);
   
   
    const handleImageClick = (clickedImage) => {
        setSelectedImage(clickedImage);
    };

    return (
        <>
            <div className='border-blue-500 container flex border overflow-x-auto'>
                {imageName.map((image, index) => (
                    <div className={`mx-5 mb-4 mt-5`}>
                        <img
                            key={index}
                            className={`mx-5 mb-4 mt-5 ${selectedImage === image.taskname ? 'border-4 border-blue-800' : ''}`}
                            src={`${API_URL}/datadir/${folderDir}/images/${image.taskname}`}
                            alt="Selected"
                            width="120"
                            height="120"
                            onClick={() => handleImageClick(image.taskname)}
                            style={{
                                maxWidth: '100vw'
                            }}
                        />
                        <div className={`text-center ${selectedImage === image.taskname ? 'text-blue-800 font-bold' : ''}`}>page-{index + 1}</div>
                    </div>
                ))}
            </div>
           
            {/* <div className='text-center'>
                <button
                    className="text-3xl px-6 py-3 mt-2 bg-blue-300 font-semibold text-gray-900 shadow-md ring-1 ring-inset ring-blue-300 hover:bg-blue-100 rounded ml-auto text"
                    onClick={(e) =>{handleTextView(e, selectedImage)}}
                >
                    {isLoading11 ? (
                        "Processing, Please wait..."
                    ) : (
                        'Text View'
                    )}
                </button>
            </div> */}

        </>
    )
}

export default ImageViewer