import { useState, useRef, useEffect } from 'react';
import { FiMenu } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../assets/Slide 4_3 - 24.svg';
import Logout from '../login/Logout';

const Nav = () => {
    const [open, setOpen] = useState(false);
    
    const location = useLocation()

    const [showDropdown, setShowDropdown] = useState(false);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <>
            <header className="border-b border-gray-300 py-1 sticky self-start" style={{
                top: 0,
                left: 0,
                right: 0,
                background: '#ffffff',
                zIndex: 1,
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            }}>
                <div className="flex justify-between items-center xl:max-w-7xl xl:mx-auto max-w-full px-1 flex-wrap">
                    <Link to="/">
                        <div className="flex justify-center">
                            {/* <span className='lg:pl-1 py-2 text-3xl hover:text-blue-200 text-blue-400 font-semibold mt-2'>AVTAAR</span> */}
                            <img
                                src={Logo}
                                alt="logo"
                                className="cursor-pointer mr-4"
                            />
                        </div>
                    </Link>

                    <FiMenu
                        className="lg:hidden block h-6 w-6 cursor-pointer"
                        onClick={() => setOpen(!open)}
                    />
                    <nav className={`${open ? 'block' : 'hidden'} lg:flex lg:items-center lg:w-auto w-full`}>
                        <ul className="text-base text-gray-600 lg:flex lg:justify-between">
                            <li className={`lg:px-5 py-2 hover:text-blue-500 font-semibold ${location.pathname === '/' ? 'text-green-700' : ''
                                }`}>
                                <Link to="/" >
                                    Home
                                </Link>
                            </li>
                            <li className={`lg:px-5 py-2 hover:text-blue-500 font-semibold ${location.pathname === '/dashboard' ? 'text-green-700' : ''
                                }`}>
                                <Link to="/dashboard" >
                                    Dashboard
                                </Link>
                            </li>
                            <li className={`lg:px-5 py-2 hover:text-blue-500 font-semibold ${location.pathname === '/archive' ? 'text-green-700' : ''
                                }`}>
                                <Link to="/archive" >
                                    Archive
                                </Link>
                            </li>
                            <li className={`lg:px-5 py-2 hover:text-blue-500 font-semibold ${location.pathname === '/about' ? 'text-green-700' : ''
                                }`}>
                                <Link to="/about" >
                                    About Us
                                </Link>
                            </li>
                            <li className={`lg:px-5 py-2 hover:text-blue-500 font-semibold ${location.pathname === '/contact' ? 'text-green-700' : ''
                                }`}>
                                <Link to="/contact" >
                                    Contact Us
                                </Link>
                            </li>
                            <li className={`lg:px-5 py-2 hover:text-blue-500 font-semibold ${location.pathname === '/settings' ? 'text-green-700' : ''
                                }`}>
                                <Link to="/settings" >
                                    Settings
                                </Link>
                            </li>
                            {/* <li className={`lg:px-5 py-2 hover:text-blue-500 font-semibold ${location.pathname === '/register' ? 'text-green-700' : ''
                        }`}>
                            <Link to="/register" >
                                Register
                            </Link>
                        </li> */}
                            <li className={`py-2 px-4 lg:px-6 lg:py-2 bg-blue-300 ${!showDropdown ? 'hover:bg-slate-200 hover:text-blue-500' : ''} border border-slate-200 rounded-xl font-semibold ${location.pathname === '/logout' ? 'text-green-700 bg-slate-200 border border-slate-100' : ''
                                }`}>
                                <Link to="#" onClick={toggleDropdown}>Profile</Link>
                                {showDropdown && (
                                    <div ref={dropdownRef} className="absolute right-4 z-10 mt-2 px-2 ml-10 origin-top-right rounded-md bg-gray-300 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div> <Logout /> </div>
                                    </div>
                                )}
                            </li>

                        </ul>
                    </nav>
                </div>
            </header>
        </>
    );
};

export default Nav;
