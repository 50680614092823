import { useState } from "react";

const Table = ({ data }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const toggleRowSelection = (rowId) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(rowId)) {
        return [];
      } else {

        return [rowId];
      }
    });
  };

  return (
    <>

      <table id="" className="table w-full table-fixed">
        <thead>
          <tr className="border border-gray-300">
            <th className="text-center border border-gray-300 w-20" scope="col">
              id
            </th>
            <th className="border border-gray-300 w-42" scope="col">
              OCR Engine
            </th>
            <th className="border border-gray-300 w-42" scope="col">
              MT Engine
            </th>
            <th className="text-center" scope="col">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((myData, index) => (
              <tr
                key={index}
                className={`${selectedRows.includes(myData.id) ? 'bg-blue-300' : ''}`}
                onClick={() => toggleRowSelection(myData.id)}
              >
                <th className="text-center border border-gray-300 w-20" scope="row">{myData.id}</th>
                <td className="border border-gray-300 w-20">{myData.ocreng || '---'}</td>
                <td className="border border-gray-300 w-42">{myData.mteng || '---'}</td>
                <td className="border border-gray-300">Current setting</td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="text-center border border-gray-300" colSpan="4">Choose engine</td>
            </tr>
          )}
        </tbody>
      </table> 
    </>
  );
};

export default Table;
