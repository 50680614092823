import React, { useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import axios from 'axios';
import DashboardView from './DashboardView';
import TranslationView from './TranslationView';
import Download from '../../components/styled/Download';
import { saveAs } from 'file-saver';
import TableView from './TableView';


const Tasks = () => {
    const { API_URL } = useContext(GlobalContext);
    const [data, setData] = useState([]);
    const [extractedText, setExtractedText] = useState('');
    const [imageName, setImageName] = useState([]);
    const [editedText, setEditedText] = useState('');
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState({});
    // eslint-disable-next-line
    // const [textViewSave, setTextViewSave] = useState([]);
    const [translatedText, setTranslatedText] = useState([])
    const [viewedRowIndex, setViewedRowIndex] = useState('')
    const [folderDir, setFolderDir] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(10);
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const storedData = localStorage.getItem('userObjectArray');
    const authTokenAccess = storedData ? JSON.parse(storedData)[0]?.auth : null

    const getTableData = async (page, postsPerPage) => {
        setLoading(true);
        try {
            const response = await axios.post(`${API_URL}/api/tasks?page=${page}&size=${postsPerPage}`, { "token": authTokenAccess }, {
                headers: {
                    "Content-Type": "application/json",
                    // Authorization: `Bearer ${authTokenAccess}`,
                },
            });
            if (response && response.data) {
                setData(response.data.data);
                setTotalPages(response.data.totalPages);
                console.log("get Data", response.data);
            } else {
                console.log("Response data is missing or invalid");
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };



    useEffect(() => {
        getTableData(currentPage, postsPerPage);
        // eslint-disable-next-line
    }, [currentPage, postsPerPage]);


    const handleSubmit = async (event, taskname, index) => {
        event.preventDefault();

        setImageName([]);
        setExtractedText("");
        setEditedText("");
        setTranslatedText([]);
        setFolderDir("");

        setIsLoading({ ...isLoading, [taskname]: true }); // Set loading to true for the specific row
        setViewedRowIndex(index);
        const formData = new FormData();
        formData.append('taskname', taskname);

        try {
            const response = await axios.post(`${API_URL}/api/dashboard`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.filename && response.status === 200) {
                setImageName(response.data.filename);
                setExtractedText(response.data.text);
                setEditedText(response.data.text);
                setTranslatedText(response.data.data);
                setFolderDir(response.data.folderDir);
            }
            console.log("response Tasks:", response.data);


            if (response.data.message) {
                alert('The requested file does not exist.');
            }
        } catch (error) {
            console.error('Error Connection:', error);
            // Handle the error, e.g., display an error message to the user.
        } finally {
            console.log("Finally....");
            setIsLoading({ ...isLoading, [taskname]: false }); // Set loading to false for the specific row
        }
    };


    const file = new Blob([editedText], { type: 'text/plain' });
    const fileUrl = URL.createObjectURL(file);

    let fileName = "";

    if (typeof imageName === 'string') {
        fileName = imageName.split('.')[0] + '.txt';
    } else {
        console.error('selectedImage is not a string...');
    }


    const formattedData = translatedText.map(row => row.join('\t')).join('\n');
    const filenameEngHin = new Blob([formattedData], { type: 'text/plain' });
    const fileUrlEngHin = URL.createObjectURL(filenameEngHin);
    const nanoTimestamp = new Date().getTime() * 1000;
    const filename_en_hin = `${nanoTimestamp}`;

    let formattedtranslatedTextC1;
    if (Array.isArray(translatedText) && translatedText.length > 0) {
        formattedtranslatedTextC1 = translatedText.map(row => (Array.isArray(row) ? row[0] : row)).join('\n');
    }
    const fileC1 = new Blob([formattedtranslatedTextC1], { type: 'text/plain' });
    const fileUrlC1 = URL.createObjectURL(fileC1);
    const nanoTimestamp1 = new Date().getTime() * 1000;
    const fileNameC1 = `${nanoTimestamp1}_source.txt`;

    let formattedtranslatedTextC2;
    if (Array.isArray(translatedText) && translatedText.length > 0) {
        formattedtranslatedTextC2 = translatedText.map(row => (Array.isArray(row) ? row[1] : row)).join('\n');
    }
    const fileC2 = new Blob([formattedtranslatedTextC2], { type: 'text/plain' });
    const fileUrlC2 = URL.createObjectURL(fileC2);
    const nanoTimestamp2 = new Date().getTime() * 1000;
    const fileNameC2 = `${nanoTimestamp2}_target.txt`


    const handleDownload = () => {
        const nanoTimestampFile = Date.now() * 1_000_000;
        const tsvString = translatedText.map(row => row.join('\t')).join('\n');
        const blob = new Blob([tsvString], { type: 'text/tab-separated-values' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${nanoTimestampFile}.txt`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };


    const downloadTranslatedDocxFile = () => {
        const formattedData = translatedText.map(row => row.join('\t')).join('\n');
        const blob = new Blob([formattedData], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

        let fileName = "";

        if (typeof imageName === 'string') {
            // Assuming the imageName is a filename with an extension, e.g., "example.docx"
            const fileNameParts = imageName.split('.');
            fileNameParts.pop(); // Remove the file extension
            fileName = fileNameParts.join('.') + '.docx';
        } else {
            console.error('selectedImage is not a string...');
            return; // Exit the function if selectedImage is not a valid string
        }

        saveAs(blob, fileName);
    };


    return (
        <>
            {Array.isArray(data) && data.length > 0 ? (
                <TableView data={data} setCurrentPage={setCurrentPage} handleSubmit={handleSubmit} totalPages={totalPages} viewedRowIndex={viewedRowIndex} isLoading={isLoading} currentPage={currentPage} setPostsPerPage={setPostsPerPage} postsPerPage={postsPerPage} />
            ) : (
                <table className="w-full mt-2">
                    <tr>
                        <td className="border border-gray-300 py-3 text-xl text-center" colSpan="3">No Task available</td>
                    </tr>
                </table>
            )}

            {extractedText.length > 0 ? (
                <div>
                    <div>
                        <DashboardView
                            API_URL={API_URL}
                            imageName={imageName}
                            folderDir={folderDir}
                            editedText={editedText}
                            setEditedText={setEditedText}
                        />
                    </div>
                </div>
            ) : ""
            }

            {translatedText.length > 0 ?
                <div className='container mt-5 mb-5'>
                    <div className='container flex flex-wrap mt-2 border-t-2 border-blue-100'></div>
                    <div className="flex">
                        <h1 className="text-3xl text-center h-10 rounded-full text-slate-800 bg-blue-300 font-bold mb-4 border border-blue-300 -ml-5 w-10 border-t-0">4</h1>
                        <span className='text-4xl ml-2 font-bold text-slate-800'>Translation</span>
                    </div>

                    <div className='ml-6 -mt-14'>
                        <div className="text-right">
                            <Download linkTitletxt="Translation Text (.txt)" linkTitleDocs="Translation Text (.docx)" handleDownload={handleDownload} downloadDocxFile={downloadTranslatedDocxFile} />
                        </div>
                        <TranslationView data={translatedText} />
                    </div>
                </div>
                : ""}
        </>
    );
};

export default Tasks;
