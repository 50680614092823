import { Link, useNavigate } from "react-router-dom";
import { handleLogout } from './AuthUtils';


const Logout = () => {
  const navigate = useNavigate();
  const storedData = localStorage.getItem('userObjectArray');
  const username = storedData ? JSON.parse(storedData)[0]?.name : null

  const onButtonClick = () => {
    handleLogout(); // Clear authentication data
    navigate('/login'); // Redirect to login page
  };


  return (
    <>
      <div className="my-3">
        <div className="text-lg mx-5 mt-2 mb-2">{username || 'Guest'}</div>
        <Link to="#" className="text-xl text-blue-500 hover:underline mx-5" onClick={onButtonClick}>Logout</Link>
      </div>
    </>
  )
}

export default Logout