import React, { useState, useContext } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { GlobalContext } from '../../context/GlobalContext';
import TranslationTable from '../../components/TranslationTable';
import TranslationEngine from '../TranslationEngine';
import Popup from "../../popup/Popup"
import ZoomInOut from '../../components/ZoomInOut';
import Download from '../../components/styled/Download';
import TextView from '../../components/TextView';
import Transcript from './Transcript';

const ImageOCRView = ({ imageName, folderDir, templateSegmenation }) => {

  const { API_URL, showToast } = useContext(GlobalContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const [extractedText, setExtractedText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading11, setIsLoading11] = useState(false);
  const [isLoading111, setIsLoading111] = useState(false);
  const [data, setData] = useState([]);
  // eslint-disable-next-line
  const [textViewSave, setTextViewSave] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState('');
  const [templateStatus, setTemplateStatus] = useState('');
  const [templateImage, setTemplateImage] = useState('');
  // eslint-disable-next-line
  const [tempFolderDir, setTempFolderDir] = useState('');
  const [transcriptActiveStatus, setTranscriptActiveStatus] = useState('')
  const [templateNameHTML, setTemplateNameHTML] = useState('')
  const [mtEnabled, setMTEnabled] = useState("")

  const handleImageClick = (clickedImage) => {
    setSelectedImage(clickedImage);
  };

  const extractTextFromImage = async () => {
    setExtractedText('');
    setEditedText('');
    setTemplateStatus('')
    if (selectedImage) {
      const formData = new FormData();
      formData.append('file', selectedImage);
      formData.append('folderDir', folderDir);

      setIsLoading(true);

      try {
        const response = await axios.post(`${API_URL}/api/extractText`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 200) {
          setExtractedText(response.data.text);
          setTranscriptActiveStatus(response.data.transcriptActive)
          setMTEnabled(response.data.mtEnabled)

          if (response.data.text == null || response.data.text.length === 0) {
            showToast('failure', 'Sorry, no text was found in the API response. Please try again.', 'top', 10);
          } else {
            setEditedText(response.data.text)
          }
          setData([])
          console.log("extracted Text:", editedText)
        }
      } catch (error) {
        console.log('Error in Extract route:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const extractTextFromAllImage = async () => {

    setExtractedText('');
    setEditedText('');
    setTemplateStatus('')
    if (selectedImage) {
      const formData = new FormData();
      formData.append('file', selectedImage);
      formData.append('folderDir', folderDir);

      setIsLoading11(true);

      try {
        const response = await axios.post(`${API_URL}/api/extractAllText`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 200) {
          setExtractedText(response.data.text);
          setTranscriptActiveStatus(response.data.transcriptActive)
          setMTEnabled(response.data.mtEnabled)

          if (response.data.text == null || response.data.text.length === 0) {
            showToast('failure', 'Sorry, no text was found in the API response. Please try again.', 'top', 10);
          } else {
            setEditedText(response.data.text)
          }
          setData([])
          console.log("extracted Text:", editedText)
        }
      } catch (error) {
        console.log('Error in Extract route:', error);
      } finally {
        setIsLoading11(false);
      }
    }
  };

 
  const handletextFileDownload = () => {
    let fileName;
    if (typeof selectedImage === 'string') {
        fileName = selectedImage.split('.')[0] + '.txt';
    } else {
        fileName = `${Date.now() * 1_000_000}.txt`;
    }
    const blob = new Blob([editedText], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const downloadDocxFile = () => {
    const blob = new Blob([editedText], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

    let fileName = "";

    if (typeof selectedImage === 'string') {
      const fileNameParts = selectedImage.split('.');
      fileNameParts.pop(); // Remove the file extension
      fileName = fileNameParts.join('.') + '.docx';
    } else {
      console.error('selectedImage is not a string...');
      return;
    }

    saveAs(blob, fileName);
  };

  const domainFetchData = async () => {
    setIsLoading1(true);

    try {
      const response = await axios.post(`${API_URL}/api/translation`, { "text": editedText, "imageName": selectedImage, "folderDir": folderDir }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      setData(response.data.data);

      if (response.data.message && response.data.message.length > 0) {
        showToast('failure', response.data.message, 'top', 10);
      }

    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading1(false);
    }
  };

  const handleEditClick = () => { setIsEditing(true) };

  const handleSaveClick = async () => {
    setIsEditing(false);
    setTextViewSave(editedText);

    try {
      const response = await axios.post(`${API_URL}/api/textViewSave`, { "text": editedText, "taskname": selectedImage, "folderDir": folderDir }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data && response.status === 200) {
        showToast('success', response.data.response, 'top', 10);
        console.log("response Tasks:", response.data.response);
      }

    } catch (error) {
      console.error('Error Connection:', error);
      // Handle the error, e.g., display an error message to the user.
    } finally {
      console.log("Finally....");
    }
  };


  const transcriptView = async () => {
    setTemplateImage("")
    setTempFolderDir("")
    setTemplateStatus("")

    if (selectedImage) {
      const formData = new FormData();
      formData.append('file', selectedImage);
      formData.append('folderDir', folderDir);

      setIsLoading111(true);

      try {
        const response = await axios.post(`${API_URL}/api/transcript`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 200) {
          setTemplateStatus(response.data.records.template)
          setTemplateImage(response.data.templateImage)
          setTempFolderDir(response.data.folderDir)
          setTemplateNameHTML(response.data.templateNameHTML)
          console.log("transcript:", response.data)
        }
      } catch (error) {
        console.log('Error in Transcript route:', error);
      } finally {
        setIsLoading111(false);
      }
    }
  };

  return (
    <>
      <div className='container flex flex-wrap mt-2 border-t-2 border-blue-100'>
        <div className="w-full sm:w-12">
          <h1 className="text-3xl text-center h-10 rounded-full text-slate-900 bg-blue-300 font-bold mb-4 border border-blue-500 -ml-5 -m-1 w-10 border-t-0 -mt-0.5">2</h1>
        </div>
      </div>

      <div className='mr-14'>
        <div
          className='border-blue-500 container flex border overflow-x-auto -mt-14 ml-14'
          style={{
            maxWidth: '100vw',

          }}
        >
          {imageName.map((image, index) => (
            <div className={`mx-5 mb-4 mt-5`} key={index}>
              <img
                className={`mx-5 ${selectedImage === image ? 'border-4 border-blue-800' : ''}`}
                src={`${API_URL}/datadir/${folderDir}/images/${image}`}
                alt="Selected"
                width="120"
                height="120"
                onClick={() => handleImageClick(image)}
                style={{
                  maxWidth: '100vw'
                }}
              />
              <div className={`text-center ${selectedImage === image ? 'text-blue-800 font-bold' : ''}`}>
                {templateSegmenation === "true" ? (index === 0 ? `page-${index}` : `segment-${index}`) : `page-${index + 1}`}

              </div>
            </div>
          ))}
        </div>
      </div>


      {selectedImage &&
        <div className='text-center'
        >
          <button
            className="text-3xl px-6 py-3 mt-2 bg-blue-300 font-semibold text-gray-900 shadow-md ring-1 ring-inset ring-blue-300 hover:bg-blue-100 ml-auto rounded-lg"
            onClick={extractTextFromImage}
          >
            {isLoading ? (
              "Processing, Please wait..."
            ) : (
              'Extract Text'
            )}
          </button>

          <button
            className="text-3xl px-6 py-3 mt-2 bg-blue-300 font-semibold text-gray-900 shadow-md ring-1 ring-inset ring-blue-300 hover:bg-blue-100 ml-2 rounded-lg"
            onClick={extractTextFromAllImage}
          >
            {isLoading11 ? (
              "Processing, Please wait..."
            ) : (
              'Extract All'
            )}
          </button>
        </div>}
      {selectedImage ?
        <div>
          <div className='container flex flex-wrap mt-2 border-t-2 border-blue-100'></div>
          <div className='flex'>
            <h1 className="text-3xl text-center h-10 rounded-full text-slate-800 bg-blue-300 font-bold border border-blue-500 -ml-5 w-10 border-t-0">3</h1>
            <span className='text-4xl ml-2 font-bold text-slate-800'>Image to Text</span>
          </div>
        </div>
        : ""}

      <div className="grid grid-cols-2 space-x-4 ml-8 -mt-7">
        {selectedImage &&
          <div className="">
            <ZoomInOut API_URL={API_URL} folderDir={folderDir} selectedImage={selectedImage} />
          </div>
        }
        <div className='-mt-3'>
          {extractedText.length > 0 && (
            <div className="container text-right">
              <div className='mb-2'>
                <button className="bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-md ring-1 ring-inset ring-gray-300 hover:bg-blue-100 rounded mr-1" onClick={handleEditClick}>Edit</button>
                <button className="bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-md ring-1 ring-inset ring-gray-300 hover:bg-blue-100 rounded mr-1" onClick={handleSaveClick}>Save</button>
                <Download linkTitletxt="OCR Text (.txt)" linkTitleDocs="OCR Text (.docx)" handleDownload={handletextFileDownload} imageName={imageName} downloadDocxFile={downloadDocxFile} />
              </div>
              <TextView editedText={editedText} isEditing={isEditing} setEditedText={setEditedText} />
            </div>
          )}
        </div>
      </div>

      <div className='text-center mt-2'>
        {transcriptActiveStatus === true ? (
          <button className="text-3xl px-6 py-3 mt-2 bg-blue-300 font-semibold text-gray-900 shadow-md ring-1 ring-inset ring-blue-300 hover:bg-blue-100 rounded-lg ml-2" onClick={transcriptView}>
            {isLoading111 ? (
              "Processing, Please wait..."
            ) : (
              'Transcript'
            )}

          </button>
        ) : ("")}
      </div >

      <div className='mt-2'>
        <Transcript templateStatus={templateStatus} API_URL={API_URL} tempFolderDir={folderDir} templateImage={templateImage} templateNameHTML={templateNameHTML} />
      </div>

      {
        editedText.length > 0 && mtEnabled === "true" ?
          <div className='mt-2'>
            <TranslationEngine title="Translate OCR Text" handleDomainClick={domainFetchData} isLoading1={isLoading1} />
          </div>
          : ""
      }

      {data && data.length > 0 ? (
        <div className='container mt-5 mb-5'>
          <div className='container flex flex-wrap mt-2 border-t-2 border-blue-100'></div>
          <div className="flex">
            <h1 className="text-3xl text-center h-10 rounded-full text-slate-800 bg-blue-300 font-bold mb-4 border border-blue-300 -ml-5 w-10 border-t-0">4</h1>
            <span className='text-4xl ml-2 font-bold text-slate-800'>Translation</span>
          </div>
          <div className='ml-8'>
            <TranslationTable data={data} selectedImage={selectedImage} folderDir={folderDir} />
          </div>
        </div>
      ) : (
        ""
      )
      }


    </>
  );
};

export default ImageOCRView;
