const TranslationView = ({ data }) => {
    return (
        <>
            <table className="w-full table-fixed">
                <thead className="text-left h-10">
                    <tr className='border border-b-gray-700'>
                        <th className='w-16 border border-slate-300 text-center'>S.No.</th>
                        <th className='w-1/2 border border-slate-300'>Source</th>
                        <th className='w-1/2 border border-slate-300'>Target</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(data) && data.length > 0 ? (
                        data.map((myData, index) => (
                            <tr className="" key={index}>
                                <th className="text-center border border-slate-300 h-10 w-16 has-tooltip" scope="row">
                                    {index+1}</th>
                                <td className="border border-slate-300 h-10 w-1/2 pl-1 has-tooltip relative">
                                    <span className='tooltip p-1 rounded bg-gray-950 text-white has-tooltip absolute'>{myData[0]}</span>
                                    {myData[0]}</td>
                                <td className="border border-slate-300 h-10 w-1/2 pl-1 has-tooltip relative">
                                    <span className='tooltip p-1 rounded bg-gray-950 text-white has-tooltip absolute'>{myData[1]}</span>
                                    {myData[1]}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td className='bg-gray-200 text-center' colSpan="3">No Available data....</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );
};

export default TranslationView;
