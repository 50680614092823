import { useState, useContext } from 'react';
import { GlobalContext } from '../context/GlobalContext';
import axios from 'axios';
// import Translation from './Translation';
import Download from '../components/styled/Download';
import { saveAs } from 'file-saver';
import EditableTable from './EditableTable';
import Popup from '../popup/Popup';


const TranslationTable = ({ data, selectedImage, folderDir }) => {

    const [tableData, setTableData] = useState(data);
    const { API_URL, showToast } = useContext(GlobalContext);
    const [alertMessage, setAlertMessage] = useState(false);
    const [message, setMessage] = useState('');

    let formattedData;
    if (Array.isArray(tableData) && tableData.length > 0) {
        formattedData = tableData.map(row => `${row.srctxt}\t${row.tgttxt}`).join('\n');
    }
    const file = new Blob([formattedData], { type: 'text/plain' });
    const fileUrl = URL.createObjectURL(file);
    const fileName = selectedImage.split('.')[0] + '.txt';

    const downloadTranslatedDocxFile = () => {
        const blob = new Blob([formattedData], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

        let fileName = "";

        if (typeof selectedImage === 'string') {
            const fileNameParts = selectedImage.split('.');
            fileNameParts.pop(); // Remove the file extension
            fileName = fileNameParts.join('.') + '.docx';
        } else {
            console.error('selectedImage is not a string...');
            return;
        }
        saveAs(blob, fileName);
    };

    const handleSave = async () => {
        const tsvStringtext = tableData.map(row => `${row.srctxt}\t${row.tgttxt}`).join('\n');

        try {
            const response = await axios.post(`${API_URL}/api/transEditSave`, { "text": tsvStringtext, "taskname": selectedImage, "folderDir": folderDir }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data && response.status === 200) {
                showToast('success', response.data.response, 'top', 10);

                console.log("response Tasks:", response.data.response);
            }

        } catch (error) {
            console.error('Error Connection:', error);
            // Handle the error, e.g., display an error message to the user.
        } finally {
            console.log("Finally....");
        }
    };

    const handleDownload = () => {
        const nanoTimestampFile = new Date().getTime() * 1000;
        const tsvString = tableData.map(row => `${row.srctxt}\t${row.tgttxt}`).join('\n');
        const blob = new Blob([tsvString], { type: 'text/tab-separated-values' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${nanoTimestampFile}.txt`;
        a.click();
        URL.revokeObjectURL(url);
    };
    return (
        <>
            <div className="text-right -mt-14">
                {/* <button className="bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-md ring-1 ring-inset ring-gray-300 hover:bg-blue-100 rounded mr-1" onClick={handleEditClick}>Edit</button> */}
                <button className="bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-md ring-1 ring-inset ring-gray-300 hover:bg-blue-100 rounded mr-1" onClick={handleSave}>Save</button>
                <Download linkTitletxt="Translation Text (.txt)" linkTitleDocs="Translation Text (.docx)" handleDownload={handleDownload} downloadDocxFile={downloadTranslatedDocxFile} />
            </div>

            {/* {Array.isArray(data) && data.length > 0 ? (<Translation data={data} />) : ''} */}
            <div className="">
                {Array.isArray(tableData) && tableData.length > 0 ? <EditableTable tableData={tableData} setTableData={setTableData} selectedImage={selectedImage} folderDir={folderDir} /> : ""}
            </div>
        </>
    );
};

export default TranslationTable;