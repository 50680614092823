
const Tasks = ({ data, handleSubmit }) => {
    return (
        <>
                <table className="w-full table-fixed mt-2">
                    <thead className="text-left h-10">
                        <tr>
                            <th className="w-1/12 border border-slate-300 text-center">S.No.</th>
                            <th className="w-1/6 border border-slate-300 text-center">Taskid</th>
                            <th className="w-1/6 border border-slate-300 text-center">Filename</th>
                            <th className="w-1/12 border border-slate-300 text-center">Pageid</th>
                            <th className="w-1/12 border border-slate-300 text-center" colSpan={2}>Action</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((myData, index) => (
                            <tr className={`w-3/5 border border-slate-300 h-10 hover:bg-blue-100 hover:border-bg-blue-400  border-bg-blue-400`} key={index}>
                                <th className="w-1/5 text-center border border-r-slate-300" scope="row">{index+1}</th>
                                <td className={`w-3/5 border border-slate-300 has-tooltip  pl-2`}>
                                    <span className='tooltip p-1 rounded bg-gray-950 text-white has-tooltip'>{myData.taskid}</span>
                                    {myData.taskid.split(".")[0].slice(4, 8) + "-" + myData.taskid.split(".")[0].slice(8, 12) + "-" + myData.taskid.split(".")[0].slice(12, 16) + "-" + myData.taskid.split(".")[0].replace(/_page_/g, '').slice(16, 20)}

                                </td>
                                <td className="w-3/5 border border-slate-300 pl-2">{myData.filename}</td>
                                <td className="w-3/5 border border-slate-300 pl-2">{myData.pageid}</td>
                                <td className="w-3/5 border border-slate-300 text-center">
                                    <button
                                        className='px-2 py-2 bg-blue-300 font-semibold text-gray-900 shadow-md ring-1 ring-inset ring-blue-400 hover:bg-blue-100 ml-auto mr-1'
                                        onClick={(e) => handleSubmit(e, myData.taskid, myData.pageid, index)}
                                        data-taskid={myData.taskid}
                                        data-pageid={myData.pageid}
                                    >
                                        Image
                                    </button>
                                </td>
                                <td className="w-3/5 border border-slate-300 text-center">
                                    <button
                                        className='px-2 py-2 bg-blue-300 font-semibold text-gray-900 shadow-md ring-1 ring-inset ring-blue-400 hover:bg-blue-100 ml-auto mr-1'
                                    >
                                        PDF
                                    </button>

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
        </>
    );
};

export default Tasks;
