// PasswordResetForm.js
import { GlobalContext } from '../context/GlobalContext';
import { inputstyle } from '../GlobalStyles';
import React, { useState, useContext } from 'react';
import axios from 'axios';

const ForgetPassword = () => {
    const [email, setEmail] = useState('');
    const { API_URL, showToast } = useContext(GlobalContext);
    const [message, setMessage] = useState('');
    // const [isLoading, setIsLoading] = useState(false);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("")
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email.trim() || !email.match(emailRegex)) {
            showToast('error', 'Enter a valid email address...', 'top', 10);
            return;
        }

        showToast('pending', 'Processing, Please Wait...', 'top', 10);

        try {
            const response = await axios.post(`${API_URL}/api/forgetPassword`, { email: email });

            if (response.data.success && response.status === 200) {
                setMessage(response.data.success);
                showToast('success', response.data.success, 'top', 10);
            } else if (response.data.failure && response.status === 200) {
                showToast('error', response.data.failure, 'top', 10);
            }
        } catch (error) {
            showToast('failure', error.message, 'top', 10);
            setMessage(error.response.data.message);
        } finally {
            console.log("Finally....")
        }
    };

    return (
        <>
            {message.length > 0 ?
                <p className='flex-col text-center text-3xl bg-green-300 py-3 mx-20'>{message}</p>
                : ""}

            <div className="flex justify-center mt-1 px-5">
                <div className="max-w-md bg-gray-200 p-5 my-form rounded shadow-lg">
                    <h1 className="text-2xl text-gray-100 text-center font-semibold mb-5">Forget Password</h1>
                    <form onSubmit={handleSubmit}>
                        <label className='text-xl text-gray-100' htmlFor="emailAddress">Email ID</label>
                        <input
                            className={`${inputstyle}`}
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={handleEmailChange}
                        />
                        <div className="text-center">
                            <button
                                className="w-24 bg-blue-600 hover:bg-blue-700 text-white py-2 rounded text-sm tracking-wide"
                                type="submit"
                            >
                                Next
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ForgetPassword;
