import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ZoomInOut = ({ selectedImage, API_URL, folderDir }) => {
  return (
    <TransformWrapper minScale={0.1} defaultScale={1} defaultPositionX={0} defaultPositionY={0}>
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <>
          <div className="tools text-right">
            <button
              className="bg-white px-2 py-1 text-xl w-8 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 hover:bg-blue-100 rounded mr-2 has-tooltip"
              onClick={() => zoomIn()}
            >
              <span className='tooltip p-1 rounded bg-blue-100 has-tooltip -mt-10' style={{ zIndex: 1 }}>Zoom in button</span>
              +
            </button>
            <button
              className="bg-white px-2 py-1 text-xl w-8 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 hover:bg-blue-100 rounded mr-2 has-tooltip"
              onClick={() => zoomOut()}
            >
              <span className='tooltip p-1 rounded bg-blue-100 has-tooltip -mt-10' style={{ zIndex: 1 }}>Zoom out button</span>
              -
            </button>
            <button
              className="bg-white px-2 py-1 text-xl w-8 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 hover:bg-blue-100 rounded has-tooltip"
              onClick={() => resetTransform()}
            >
              <span className='tooltip p-1 rounded bg-blue-100 has-tooltip -mt-10' style={{ zIndex: 1 }}>Reset Zoom</span>
              x
            </button>
          </div>
          <TransformComponent>
            <img
              src={`${API_URL}/datadir/${folderDir}/images/${selectedImage}`}
              alt="Selected"
              className="mt-1 w-full"
              style={{
                border: selectedImage ? '1px solid black' : 'none',
                maxHeight:"100%"
              }}
            />
          </TransformComponent>
        </>
      )}
    </TransformWrapper>
  );
};

export default ZoomInOut;
