import React, { useRef, useEffect } from 'react';

const HighlightedText = ({ text, highlight }) => {
  if (!highlight) {
    return <>{text}</>;
  }

  const cleanedHighlight = highlight.replace(/[.*+-?^${}()|[\]\\]/g, '');
  const searchTerms = cleanedHighlight.toLowerCase().split(' ');
  const regex = new RegExp(`(${searchTerms.join('|')})`, 'gi');
  const parts = text.split(regex);

  return (
    <>
      {parts.map((part, index) =>
        searchTerms.includes(part.toLowerCase()) ? (
          <span key={index} style={{ backgroundColor: 'yellow' }}>
            {part}
          </span>
        ) : (
          part
        )
      )}
    </>
  );
};



const TextView = ({ setEditedText, editedText, isEditing, searchText }) => {
  const textareaRef = useRef(null);

  console.log("search text textView in highlight mode:", searchText)

  useEffect(() => {
    // Automatically adjust the height of the textarea to match its content
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset the height first
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [editedText, isEditing]);

  const ValueTextArea = searchText ? (
    <span><HighlightedText text={editedText} highlight={searchText} /></span>
  ) : (
    <div>{editedText}</div>
  );

  return (
    <div className="bg-white shadow-md">
      {isEditing ? (
        <div className='border border-gray-300 rounded-lg'>
          <textarea
            ref={textareaRef}
            className="text-justify wrap-text mx-4 focus:outline-none focus:ring focus:ring-blue-300 focus:px-2"
            style={{ width: '100%', maxHeight: '100vh', overflowY: 'auto', resize: 'none' }}
            value={editedText}
            onChange={(e) => setEditedText(e.target.value)}
          />
        </div>
      ) : (
        <pre className="border border-gray-300 rounded-lg text-left whitespace-pre-wrap"
          style={{ width: '100%', maxHeight: '100vh', overflowY: 'auto', resize: 'none' }}
        >
          <div className='mx-4'>
            {ValueTextArea}
          </div>
        </pre>
      )}
    </div>
  );
};

export default TextView;
