import { Link } from 'react-router-dom';

const LinkComponent = ({ handleOnClick, linkTitle  }) => {
    return (
        <>
            <Link
                to="#"
                className="text-gray-700 block px-2 py-2 text-sm hover:bg-blue-100"
                role="menuitem"
                tabIndex="-1"
                id="menu-item-0"
                onClick={handleOnClick}
            >
                {linkTitle}
            </Link>
        </>
    )
}

export default LinkComponent