import ZoomInOut from '../../components/ZoomInOut';
import TextView from '../../components/TextView';
import Download from '../../components/styled/Download';
import { saveAs } from 'file-saver';
import { DownloadDocuments } from '../styled/DownloadDocuments';



const DashboardView = ({ API_URL, imageName, editedText, isEditing, setEditedText, folderDir, searchText }) => {

    const downloadDocxFile = () => {
        const blob = new Blob([editedText], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

        let fileName = "";

        if (typeof imageName === 'string') {
            const fileNameParts = imageName.split('.');
            fileNameParts.pop(); // Remove the file extension
            fileName = fileNameParts.join('.') + '.docx';
        } else {
            console.error('selectedImage is not a string...');
            return;
        }

        saveAs(blob, fileName);
    };

    const handletextFileDownload = () => {
        let fileName;
        if (typeof imageName === 'string') {
            fileName = imageName.split('.')[0] + '.txt';
        } else {
            fileName = `${Date.now() * 1_000_000}.txt`;
        }
        const blob = new Blob([editedText], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.txt`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    const filefolder = 'texts';
    const filename =  imageName.split('.')[0] + '.txt';
    const downloadFile = () => DownloadDocuments({ API_URL, folderDir, filefolder, filename });
    

    return (
        <>
            <div className='container flex flex-wrap mt-2 border-t-2 border-blue-100'></div>
            <div className='flex'>
                <h1 className="text-3xl text-center h-10 rounded-full text-slate-800 bg-blue-300 font-bold border border-blue-500 -ml-5 w-10 border-t-0">3</h1>
                <span className='text-4xl ml-2 font-bold text-slate-800'>Image to Text</span>
            </div>
            <div className="grid grid-cols-2 space-x-4 ml-8 -mt-7">

                <div className="">
                    <ZoomInOut API_URL={API_URL} folderDir={folderDir} selectedImage={imageName} />
                </div>

                <div className='-mt-3'>

                    <div className="container text-right">
                        <div className='mb-2'>
                            <Download linkTitletxt="OCR Text (.txt)" linkTitleDocs="OCR Text (.docx)" handleDownload={handletextFileDownload} downloadDocxFile={downloadDocxFile} />
                        </div>

                        <TextView editedText={editedText} isEditing={isEditing} setEditedText={setEditedText} searchText={searchText} />
                    </div>
                </div>
            </div>

            <button onClick={downloadFile}>
                Download File
            </button>
        </>
    );
};

export default DashboardView;
