import IconsSubmit from '../../components/styled/IconsSubmit';
import PaginationModule from '../../pagination/PaginationModule';
import { BsDownload } from "react-icons/bs";
import { useState, useRef, useEffect } from 'react';
import DownlodDashboardsFile from './DownlodDashboardsFile';

const TableView = ({ data, handleSubmit, viewedRowIndex, currentPage, totalPages, setPostsPerPage, isLoading, setCurrentPage, postsPerPage }) => {


    function formatDateString(originalDateString) {
        const dateParts = originalDateString.split(/[- :]/);

        // Extract year, month, day, hour, minute
        //const year = dateParts[0];
        const month = dateParts[1];
        const day = dateParts[2];
        const hour = dateParts[4];
        const minute = dateParts[5];

        // Create the formatted string
        const formattedString = `${month}-${day} ${hour}:${minute}`;

        return formattedString;
    }

    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const dropdownRef = useRef(null);
    const [ocrStatus, setOcr] = useState(null);
    const [mtStatus, setMt] = useState(null);

    const toggleDropdown = (taskId, ocr, mt) => {
        if (selectedTaskId === taskId && showDropdown) {
            setShowDropdown(false);
            setSelectedTaskId(null);
        } else {
            setSelectedTaskId(taskId);
            setShowDropdown(true);
        }
        setOcr(ocr);
        setMt(mt);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
            setSelectedTaskId(null);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



    return (
        <>
            <table className="w-full table-fixed mt-2">
                <thead className="text-left h-10">
                    <tr>
                        <th className="w-1/12 border border-slate-300 text-center" rowSpan={2}>S.No.</th>
                        <th className="w-1/6 border border-slate-300 text-center" rowSpan={2}>Task Id</th>
                        <th className="w-1/6 border border-slate-300 text-center" colSpan={2}>Language</th>
                        <th className="w-1/12 border border-slate-300 text-center" rowSpan={2}>OCR</th>
                        <th className="w-1/12 border border-slate-300 text-center" rowSpan={2}>MT</th>
                        <th className="w-1/3 border border-slate-300 text-center" rowSpan={2}>Filename</th>
                        <th className="w-1/12 border border-slate-300 text-center" rowSpan={2}>Status</th>
                        <th className="w-28 border border-slate-300 text-center" rowSpan={2}>Time</th>
                        <th className="w-1/6 border border-slate-300 text-center" rowSpan={2}>Action</th>
                    </tr>
                    <tr>
                        <th className="border border-slate-300 text-center" >
                            Source
                        </th>
                        <th className="border border-slate-300 text-center" >Target
                        </th>
                        <th className="border border-slate-300 text-center"></th>
                        <th className="border border-slate-300 text-center"></th>
                        <th className="border border-slate-300 text-center"></th>
                        <th className="border border-slate-300 text-center"></th>
                        <th className="border border-slate-300 text-center"></th>
                        <th className="border border-slate-300 text-center"></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((myData, index) => (
                        <tr className={`w-3/5 border border-slate-300 h-10 ${viewedRowIndex === index ? 'bg-blue-300 border x-2 py-2 mt-2 b font-semibold text-gray-900 shadow-md hover:bg-blue-100 ml-auto mr-1 border-bg-blue-400' : ''} hover:bg-blue-100 hover:border-bg-blue-400 border border-bg-blue-400`} key={index}>
                            <th className="w-1/5 text-center border border-slate-300" scope="row">{index + 1}</th>
                            <td className={`w-3/5 border border-slate-300 has-tooltip pl-1 break-words`}>
                                <span className='tooltip p-1 rounded bg-gray-950 text-white has-tooltip'>{myData.taskid}</span>
                                {myData.taskid.split(".")[0].slice(4, 8) + "-" + myData.taskid.split(".")[0].slice(8, 12) + "-" + myData.taskid.split(".")[0].slice(12, 16) + "-" + myData.taskid.split(".")[0].slice(16, 20)}

                            </td>
                            <td className="w-3/5 border border-slate-300 pl-1">{myData.srclang}</td>
                            <td className="w-3/5 border border-slate-300 pl-1">{myData.tgtlang || "None"}</td>
                            <td className="w-3/5 border border-slate-300 pl-1">{myData.ocr === 'true' ? 'OCR' : 'No OCR'}</td>
                            <td className="w-3/5 border border-slate-300 pl-1">{myData.mt === 'true' ? 'MT' : 'No MT'}</td>
                            <td className="w-3/5 border border-slate-300 pl-1 break-words">{myData.filename} (Page {myData.taskname.includes('-') ? myData.taskname.split('-')[1].split('.')[0] : '1'}) </td>
                            <td className="w-3/5 border border-slate-300 pl-1">{myData.status}</td>
                            <td className="w-3/5 border border-slate-300 has-tooltip pl-1">
                                <span className='tooltip p-1 rounded bg-gray-950 text-white has-tooltip'>{myData.date}</span>
                                <span className="tooltip-arrow" data-popper-arrow></span>
                                {formatDateString(myData.date)}</td>
                            <td className="w-1/5 border border-slate-300 pl-1">
                                <div className='flex justify-around items-center'>
                                    <button
                                        className='px-2 py-2 bg-blue-300 font-semibold text-gray-900 shadow-md ring-1 ring-inset ring-blue-400 hover:bg-blue-100'
                                        onClick={(e) => handleSubmit(e, myData.taskname, index)}
                                        data-taskname={myData.taskname}
                                    >
                                        {isLoading[myData.taskname] ? <IconsSubmit /> : 'View'}
                                    </button>
                                    <button
                                        className='px-2 py-2 bg-blue-300 font-semibold text-gray-900 shadow-md ring-1 ring-inset ring-blue-400 hover:bg-blue-100'
                                        data-taskname={myData.taskname}
                                        onClick={() => toggleDropdown(myData.taskname, myData.ocr, myData.mt)}
                                    >
                                        <BsDownload size={22} />
                                        {showDropdown && selectedTaskId === myData.taskname && (
                                            <div
                                                ref={dropdownRef}
                                                className="absolute right-0 z-99 mt-2 px-1 py-2 bg-gray-300 rounded-md shadow-lg ring-1 ring-black ring-opacity-5"
                                            >
                                                <div style={{textAlign:'start'}}>
                                                    <DownlodDashboardsFile selectedTaskId={selectedTaskId} ocrStatus={ocrStatus} mtStatus={mtStatus} />
                                                </div>
                                            </div>
                                        )}
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <PaginationModule totalPages={totalPages} postsPerPage={postsPerPage} setPostsPerPage={setPostsPerPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </>
    )
}

export default TableView