import { useState, useContext } from 'react';
import { GlobalContext } from '../context/GlobalContext';
import axios from 'axios';

const EditableTable = ({ tableData, setTableData, selectedImage, folderDir }) => {
    const { API_URL } = useContext(GlobalContext);
    const [alertMessage, setAlertMessage] = useState(false);
    const [message, setMessage] = useState('');
    
    const handleContentChange = (e, index, field) => {
        const updatedTableData = [...tableData];
        updatedTableData[index][field] = e.target.innerText;
        setTableData(updatedTableData);
    };

    // const handleSaveClick = () => {
    //     console.log('Saved data:', tableData);
    //     console.log("tabledata:\n", tableData)
    // };

    
    // const handleDownload = () => {
    //     const tsvString = tableData.map(row => `${row.srctxt}\t${row.tgttxt}`).join('\n');
    //     const blob = new Blob([tsvString], { type: 'text/tab-separated-values' });
    //     const url = URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = 'tableData.txt';
    //     a.click();
    //     URL.revokeObjectURL(url);
    // };

    const tableContainerStyle = {
        overflowX: 'auto',
    };

    const tableStyle = {
        display: 'table',
        width: '100%',
        borderCollapse: 'collapse',
    };

    const rowStyle = {
        display: 'table-row',
    };

    const cellStyle = {
        display: 'table-cell',
        border: '1px solid #ddd',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        minWidth: '50px',
    };

    const headerStyle = {
        ...cellStyle,
        backgroundColor: '#f2f2f2',
        fontWeight: 'bold',
    };


    return (
        <div className='full-w' style={tableContainerStyle}>
            {/* <div className='text-right'>
                <button className="bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-md ring-1 ring-inset ring-gray-300 hover:bg-blue-100 rounded mr-1" onClick={handleSave}>Save</button>
                <button className='bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-md ring-1 ring-inset ring-gray-300 hover:bg-blue-100 rounded' onClick={handleDownload}>Download</button>
            </div> */}

            <div style={tableStyle}>
                <div style={rowStyle}>
                    <div className='text-xl py-3 text-center' style={{ ...headerStyle, width: '50px' }}>ID</div>
                    <div className='text-xl py-3 text-center' style={{ ...headerStyle, width: '300px' }}>Src Text</div>
                    <div className='text-xl py-3 text-center' style={{ ...headerStyle, width: '300px' }}>Tgt Text</div>
                    {/* <div className='text-xl py-3 text-center' style={{ ...headerStyle, width: '100px' }}>Action</div> */}
                </div>

                {tableData.map((row, index) => (
                    <div style={rowStyle} key={index}>
                        <div className="text-center" style={cellStyle}>{index + 1}</div>
                        <div
                            className="px-3"
                            style={cellStyle}
                            contentEditable
                            suppressContentEditableWarning
                            onBlur={(e) => handleContentChange(e, index, 'srctxt')}
                        >
                            {row.srctxt}
                        </div>
                        <div
                            className="px-3"
                            style={cellStyle}
                            contentEditable
                            suppressContentEditableWarning
                            onBlur={(e) => handleContentChange(e, index, 'tgttxt')}
                        >
                            {row.tgttxt}
                        </div>
                        {/* <div className="text-center" style={cellStyle}>
                            <button className="bg-blue-300 py-2" style={buttonStyle} onClick={handleSave}>Save</button>
                        </div> */}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EditableTable;
