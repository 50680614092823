import React, { useState, useContext } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import axios from "axios";

function OptionSelection({ setResponse, response }) {
    const { API_URL } = useContext(GlobalContext);
    const [selectedOption1, setSelectedOption1] = useState('');
    const [selectedOption2, setSelectedOption2] = useState('');
    const storedData = localStorage.getItem('userObjectArray');
    const authTokenAccess = storedData ? JSON.parse(storedData)[0]?.auth : null

    const handleOptionChange1 = (event) => {
        const newValue = event.target.value;
        setSelectedOption1(newValue);
    };

    const handleOptionChange2 = (event) => {
        const newValue = event.target.value;
        setSelectedOption2(newValue);
    };

    const handleSubmit = async () => {

        if (selectedOption1.trim() === "" || selectedOption2.trim() === "") {
            console.log('Please select both options before submitting.');
            setResponse('Please select both options before submitting.');
            return;
        }

        setResponse("")

        try {
            const responseAPI = await axios.post(`${API_URL}/api/update`, { "ocreng": selectedOption1, "mteng": selectedOption2, "token": authTokenAccess }, {
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${authTokenAccess}`,
                }
            });
            setResponse(responseAPI.data.response)

        } catch (error) {
            console.log(error);
        } finally {
        }
    };

    return (
        <>
            {response && response.length > 0 ? <div className="bg-green-300 mt-2 py-3 px-2"> {response} </div> : ''}

            <div className="flex space-x-4">
                <div className="relative w-96">
                    <select
                        className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                        value={selectedOption1}
                        onChange={handleOptionChange1}
                    >
                        <option value="">Select OCR Engine</option>
                        <option value="Bhashini-OCR">Bhashini-OCR</option>
                        <option value="IIITH-OCR">IIITH-OCR</option>
                        <option value="Tesseract-OCR">Tesseract-OCR</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                    </div>
                </div>

                <div className="relative w-96">
                    <select
                        className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                        value={selectedOption2}
                        onChange={handleOptionChange2}
                    >
                        <option value="">Select MT Engine</option>
                        <option value="Bhashini-MT">Bhashini-MT </option>
                        <option value="Google-MT">Google-MT</option>
                        <option value="IIITH-ONEMT">IIITH-ONEMT</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                    </div>
                </div>
                <div className="relative w-64">
                    <button
                        type="button"
                        className="border px-4 py-2 rounded-lg bg-blue-300 font-semibold text-gray-900 shadow-md ring-1 ring-inset ring-blue-400 hover:bg-blue-100 ml-auto"
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                </div>
            </div>

        </>
    );
}

export default OptionSelection;
