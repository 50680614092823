import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { GlobalContext } from '../context/GlobalContext';
import Table from '../components/settings/Table'
import OptionSelection from '../components/settings/OptionSelection'

const Settings = () => {
  const { API_URL } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [response, setResponse] = useState();
  const storedData = localStorage.getItem('userObjectArray');
  const authTokenAccess = storedData ? JSON.parse(storedData)[0]?.auth : null

  useEffect(() => {
    const getSettingsData = async () => {
      try {
        const response = await axios.post(`${API_URL}/api/getSettings`, {"token": authTokenAccess}, {
          headers: {
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${authTokenAccess}`,
          },
        });
        setData(response.data.data)
      } catch (error) {
        console.log(error);
      }
    };
    getSettingsData();
  }, [response, authTokenAccess, API_URL]);

  return (
    <>
      <div className='mr-20 ml-20 mb-5'>
        <div className="mt-1 text-3xl">
          Settings
        </div>
        <div className='mt-5 w-1/2'>
          <OptionSelection setResponse={setResponse} response={response} />
        </div>
        <div className='w-1/2 mt-3'>
          <Table data={data} />
        </div>
      </div>

    </>
  )
}

export default Settings