
const TranslationEngine = ({title, handleDomainClick, isLoading1 }) => {
  
  return (
    <>
      <div className="relative text-center">
        <div>
          <button
            type="button"
            className="text-3xl px-6 py-3 mt-2 bg-blue-300 font-semibold text-gray-900 shadow-md ring-1 ring-inset ring-blue-300 hover:bg-blue-100 rounded-lg ml-auto"
            id="menu-button"
            aria-haspopup="true"
            onClick={handleDomainClick}
          >
            {isLoading1 ?  "Processing, Please wait..." : title}
          </button>
        </div>
      </div>
    </>
  );
};

export default TranslationEngine;
