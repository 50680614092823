import { useState } from 'react'
// import Input from '../components/input/Input'
import EditableTable from '../components/EditableTable';


const ContactUs = () => {
  const initialData = [
    { id: 1, srctxt: 'Source 1', tgttxt: 'Target 1' },
    { id: 2, srctxt: 'Source 2', tgttxt: 'Target 2' },
    { id: 3, srctxt: 'Source 3', tgttxt: 'Target 3' },
    { id: 4, srctxt: 'Source 4', tgttxt: 'Target 4' },
]

const [tableData, setTableData] = useState(initialData);

  return (
    <>
      <div className="mx-20 text-justify p-4 mt-1 rounded-2xl w-1/2 h-96"
      >
        <h1 className='text-3xl font-bold'> Head Office: </h1>
        <p>For more information on our product and services please contact us at:</p>
        <h1>eBhasha Setu Language Services private Ltd.</h1>

        <p>Vindhya B5-106 & B5-107 Ground Floor,
        </p>
        <p>IIIT-H Campus, Survey no. 25,
        </p>
        <p>Gachibowli, Hyderabad-500032
        </p>
        <p> <span className="">Phone:</span> +91-9968036443
        </p>
        <p><span className="">Email ID:</span>  info@ebhashasetu.com
        </p>
      </div>
      {/* <Input /> */}
      {/* <div>
      {Array.isArray(tableData) && tableData.length > 0 ? <EditableTable tableData={tableData} setTableData={setTableData} /> : ""}
      </div> */}
    </>
  )
}

export default ContactUs