import React, { useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../context/GlobalContext';

const TemplateView = ({templateNameHTML, folderDir}) => {
    const { API_URL } = useContext(GlobalContext);
    const [htmlContent, setHtmlContent] = useState('');
    
    useEffect(() => {
        const fetchHtmlFile = async () => {
            try {
                const response = await fetch(`${API_URL}/datadir/${folderDir}/html/${templateNameHTML}.html`);
                const content = await response.text();
                setHtmlContent(content);
            } catch (error) {
                console.error('Error fetching HTML file:', error);
            }
        };

        fetchHtmlFile();
    }, [API_URL, folderDir, templateNameHTML]);

    return (
        <div className='border border-gray-300 mx-3'>
            <div className='mx-5' dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </div>
    );
}

export default TemplateView;
