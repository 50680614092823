import { useState } from 'react';
import ImageOCRView from '../components/home/ImageOCRView';
import TasksS1 from '../components/home/TasksS1'

const Home = () => {
    const [imgListName, setImgListName] = useState([]);
    const [folderDirName, setFolderDirName] = useState('');
    const [templateSegmenation, setTemplateSegmenation] = useState("");

    return (
        <>
            <div className='mx-20'>
                <TasksS1 setImgListName={setImgListName} setFolderDirName={setFolderDirName} setTemplateSegmenation={setTemplateSegmenation} />
                
                {imgListName && imgListName.length > 0 ? 
                <ImageOCRView imageName={imgListName} folderDir={folderDirName} templateSegmenation={templateSegmenation} /> 
                : '' }
            </div>
            
        </>
    )
}

export default Home
