import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import GlobalProvider from './context/GlobalProvider';
import Nav from './layout/Nav';
import Home from './page/Home';
import About from './page/About';
import ContactUs from './page/ContactUs';
import Settings from './page/Settings';
import Register from './login/Register';
import Login from './login/Login';
import Logout from './login/Logout';
import Dashboard from './page/Dashboard';
import Archive from './page/Archive';
import { startTokenExpirationCheck } from './login/AuthUtils';
import InactivityLogout from './login/InactivityLogout';
import ForgetPassword from './login/ForgetPassword';
import ResetPassword from './login/ResetPassword';


function App() {
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    const storedData = localStorage.getItem('userObjectArray');
    const authTokenAccess = storedData ? JSON.parse(storedData)[0]?.auth : null
    const username = storedData ? JSON.parse(storedData)[0]?.username : null
    console.log("username:",username, "auth Token Access:", authTokenAccess);

    if (authTokenAccess && authTokenAccess.length > 0) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, []);

  useEffect(() => {
    startTokenExpirationCheck();
  }, []);


  return (
    <>
      <GlobalProvider>
        <BrowserRouter>
          <div className="min-h-screen flex flex-col">
            <main className="flex-grow" >
              {isLogin ? (
                <>
                  <Nav />
                  <Routes>
                    <Route path="/" element={<Home />} />
                     <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/archive" element={<Archive />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Logout isLogin={isLogin} setIsLogin={setIsLogin} />} />
                  </Routes>
                </>
              ) : (
                <Routes>
                  <Route path="*" element={<Login />} />
                  <Route path="/forgotPassword" element={<ForgetPassword />} />
                  <Route path="resetPassword/:userid/:token" element={<ResetPassword />} />
                  <Route path="/register" element={<Register />} />
                </Routes>

              )}
            </main>
            <footer className="w-full text-3xl text-gray-600 bg-gray-100 border-t border-gray-300 py-4 text-center">
              <small>eBhasha Setu Language Services Pvt Ltd. {new Date().getFullYear()} | All Rights Reserved</small>
              <InactivityLogout />
            </footer>
          </div>
        </BrowserRouter>
      </GlobalProvider>
    </>
  );
}

export default App;
