import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export const DownloadDocuments = async ({ API_URL, folderDir, filefolder, filename }) => {
    const fileUrl = `${API_URL}/datadir/${folderDir}/${filefolder}/${filename}`;
    const response = await fetch(fileUrl);
    if (!response.ok) {
      console.log("response File Download status:", response.status)
      throw new Error('Failed to download file');
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
  
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };
  
export const downloadFilesAsZip = async ({ API_URL, folderDir, filefolder, taskid }) => {
    try {
      const zip = new JSZip();
      const baseUrl = `${API_URL}/datadir/${folderDir}/${filefolder}`;
      console.log("Base URL:", baseUrl);
      const fileListResponse = await axios.get(`${baseUrl}`);
      console.log("API Response:", fileListResponse);
      const fileList = fileListResponse.data;
      console.log("zipdata:", fileList)
      
      for (const fileName of fileList) {
        const fileResponse = await axios.get(`${baseUrl}${fileName}`, { responseType: 'blob' });
        zip.file(fileName, fileResponse.data);
      }
  
      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, `${taskid}_${filefolder}.zip`);
    } catch (error) {
      console.error('Error downloading files:', error);
    }
  };