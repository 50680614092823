import React, { useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import { DownloadDocuments, downloadFilesAsZip } from '../styled/DownloadDocuments';
import LinkComponent from '../styled/LinkComponent';

const DownlodDashboardsFile = ({ selectedTaskId, ocrStatus, mtStatus }) => {
  const { API_URL, showToast } = useContext(GlobalContext);
  const [error, setError] = useState(null);
  const storedData = localStorage.getItem('userObjectArray');
  const authTokenAccess = storedData ? JSON.parse(storedData)[0]?.auth : null
  const userId = storedData ? JSON.parse(storedData)[0]?.id : null

  const downloadFile = async (filefolder) => {
    const taskidPart = selectedTaskId.split('.')[0];
    const filename = `${taskidPart}.txt`;
    const taskid = taskidPart.includes('-') ? taskidPart.split('-')[0] : taskidPart;
    const folderDir = `${userId}/${taskid}`;

    try {
      const response = await DownloadDocuments({ API_URL, folderDir, filefolder, filename, });
    } catch (error) {
      console.log(`File not found: ${filefolder}: ${error.message}:`);
      showToast('error', `File not found ${filefolder}: ${error.message}`, 'top', 10);
    }
  };

  const downloadZipFile = async (filefolder) => {
    const taskidPart = selectedTaskId.split('.')[0];
    const taskid = taskidPart.includes('-') ? taskidPart.split('-')[0] : taskidPart;
    const folderDir = `${userId}/${taskid}`;
  
    try {
      await downloadFilesAsZip({ API_URL, folderDir, filefolder, taskid });
    } catch (error) {
      console.log(`File not found: ${filefolder}: ${error.message}`);
      showToast('error', `File not found ${filefolder}: ${error.message}`, 'top', 10);
    }
  };
  



  return (
    <>
      {ocrStatus === "true" && (
        <div>
          <LinkComponent linkTitle="OCR Text (.txt)" handleOnClick={() => downloadFile('texts')} />
          <LinkComponent linkTitle="Edited OCR Text (.txt)" handleOnClick={() => downloadFile('modified-texts')} />
          {/* <LinkComponent linkTitle="OCR ZIP Text (.txt)" handleOnClick={() => downloadZipFile('texts')} /> */}
        </div>
      )}

      {mtStatus === "true" && (
        <div>
          <LinkComponent linkTitle="Translation Text (.txt)" handleOnClick={() => downloadFile('translation')} />
          <LinkComponent linkTitle="Edited Translation Text (.txt)" handleOnClick={() => downloadFile('translation-edit')} />
        </div>
      )}
    </>
  )
}

export default DownlodDashboardsFile