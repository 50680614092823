// ToastNotification.js

import React from 'react';

const ToastNotification = ({ type, message, position, distance }) => {
  const getToastStyle = () => {
    switch (type) {
      case 'success':
        return { backgroundColor: '#4CAF50', color: '#fff' };
      case 'error':
        return { backgroundColor: '#f44336', color: '#fff' };
      case 'pending':
        return { backgroundColor: '#2196F3', color: '#fff' };
      case 'failure':
        return { backgroundColor: '#FF9800', color: '#fff' };
      default:
        return {};
    }
  };

  const getPositionStyle = () => {
    switch (position) {
      case 'top':
        return { top: `${distance}px`, left: '50%', transform: 'translateX(-50%)' };
      case 'bottom':
        return { bottom: `${distance}px` };
      case 'left':
        return { left: `${distance}px` };
      case 'right':
        return { right: `${distance}px` };
      default:
        return {};
    }
  };

  return (
    <div
    className=''
      style={{
        position: 'fixed',
        padding: '15px',
        zIndex:9999,
        ...getToastStyle(),
        ...getPositionStyle(),
      }}
    >
      {message}
    </div>
  );
};

export default ToastNotification;
