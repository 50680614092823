import { useState } from 'react';
import { GlobalContext } from './GlobalContext';
import ToastNotification from './ToastNotification';


const GlobalProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const API_URL = process.env.REACT_APP_API_URL;

  const [toast, setToast] = useState(null);

  const showToast = (type, message, position, distance) => {
    setToast({ type, message, position, distance });
    setTimeout(() => {
      setToast(null);
    }, 5000); // Adjust the duration as needed
  };

  const contextValue = { API_URL, auth, setAuth, showToast };

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
      {toast && <ToastNotification type={toast.type} message={toast.message} position={toast.position}
          distance={toast.distance} />}
    </GlobalContext.Provider>
  );
}

export default GlobalProvider;
