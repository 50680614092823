import React, { useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { GlobalContext } from '../context/GlobalContext';
import { inputstyle } from '../GlobalStyles';

function ResetPassword() {
    const navigate = useNavigate();
    const { API_URL, showToast } = useContext(GlobalContext);
    const { userid, token } = useParams();
    const [formData, setFormData] = useState({
        Npassword: '',
        Cpassword: '',
    });
    const [formErrors, setFormErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        // Clear the error message when the user starts typing
        setFormErrors({
            ...formErrors,
            [name]: '',
        });
    };

    const validateForm = () => {
        const errors = {};
        if (formData.Npassword.trim() === '') {
            errors.Npassword = 'This field is required';
        } else {
            const password1Regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%])[A-Za-z\d@#$%.]{8,}$/;

            if (!password1Regex.test(formData.Cpassword)) {
                errors.password1 = 'Password must contain at least 8 characters with one uppercase letter, one lowercase letter, one digit, and one of the following special characters: @#$%.';
            }
        }

        if (formData.Cpassword.trim() === '') {
            errors.Cpassword = 'This field is required';
        } else {
            const password2Regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%])[A-Za-z\d@#$%.]{8,}$/;

            if (!password2Regex.test(formData.Cpassword)) {
                errors.password2 = 'Password must contain at least 8 characters with one uppercase letter, one lowercase letter, one digit, and one of the following special characters: @#$%.';
            }
        }

        // Check if passwords match
        if (formData.Npassword !== formData.Cpassword) {
            errors.confirmPassword = 'Passwords do not match';
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };


    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            showToast('pending', 'Processing, Please Wait...', 'top', 10);
            try {
                const response = await axios.post(`${API_URL}/api/resetPassword/${userid}/${token}`,
                    {
                        newPassword: formData.Cpassword
                    });

                console.log("(response.data);", response.data);
                if (response.data.success && response.status === 200) {
                    showToast('success', response.data.success, 'top', 10);
                    navigate('/login')
                } else if (response.data.message) {
                    showToast('failure', response.data.message, 'top', 10);
                    console.error('Change password failed.');
                }
            } catch (error) {
                showToast('failure', error.message, 'top', 10);
                console.log(error);
            } finally {
                console.log("here....")
            }
        }
    };

    return (
        <>
            <div className="flex justify-center mt-1 px-5">
                <div className="max-w-md bg-gray-200 p-5 my-form rounded shadow-lg">
                    <h1 className="text-2xl text-gray-100 text-center font-semibold mb-5">Reset Password</h1>
                    <form>
                        <label className='text-xl text-gray-100' htmlFor="password">New Password</label>
                        <input
                            className={`${inputstyle} ${formErrors.Npassword ? 'w-full border-red-500 mb-0' : ''}`}
                            type="password"
                            placeholder="Password"
                            name="Npassword"
                            value={formData.Npassword}
                            onChange={handleInputChange}
                        />
                        {formErrors.Npassword && (
                            <p className="text-red-500 text-xs italic">{formErrors.Npassword}</p>
                        )}

                        <label className='text-xl text-gray-100' htmlFor="confirmedPassword">Confirmed Password</label>
                        <input
                            className={`w-full px-4 py-2 border rounded mb-4 focus:outline-none focus:ring focus:ring-blue-300 ${formErrors.Cpassword ? 'w-full border-red-500' : ''
                                }`}
                            type="password"
                            placeholder="Confirmed Password"
                            name="Cpassword"
                            value={formData.Cpassword}
                            onChange={handleInputChange}
                        />

                        {formErrors.Cpassword && (
                            <p className="text-red-500 text-xs italic">{formErrors.Cpassword}</p>
                        )}

                        {formErrors.confirmPassword && (
                            <p className="text-red-500 text-xs italic">{formErrors.confirmPassword}</p>
                        )}

                        <div className="text-center">
                            <button
                                className="w-24 bg-blue-600 hover:bg-blue-700 text-white py-2 rounded text-sm tracking-wide"
                                type="button"
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default ResetPassword;
