import ZoomInOut from '../../components/ZoomInOut'
import TemplateView from '../../templates/TemplateView'

const Transcript = ({ templateStatus, API_URL, tempFolderDir, templateImage, templateNameHTML }) => {
    console.log("transcript:", templateStatus)
    return (
        <>
            {templateStatus === 'true' ?
                <div className="flex flex-wrap border-t-2 border-blue-100 mt-0.5">
                    <div className="w-full sm:w-12">
                        <h1 className="text-3xl text-center h-10 rounded-full text-slate-900 bg-blue-300 font-bold mb-4 border border-blue-500 -ml-5 -m-1 w-10 border-t-0 -mt-0.5">4</h1>
                    </div>
                    <div className=''>
                        <span className='text-3xl ml-2 font-bold text-slate-800'>Transcript</span>
                    </div>

                    <div className="w-full">
                        <div className="grid grid-cols-2 space-x-4 ml-8 -mt-14">
                            <div>
                                <ZoomInOut API_URL={API_URL} folderDir={tempFolderDir} selectedImage={templateImage} />
                            </div>

                            <div className=''>
                                <div className='mt-10'></div>
                                <TemplateView folderDir={tempFolderDir} templateNameHTML={templateNameHTML} />
                            </div>
                        </div>
                    </div>
                </div>
                : ""}
        </>
    )
}

export default Transcript