import React, { useState } from 'react';
import Search from "../components/archieve/Search"
import ArchiveView from "../components/archieve/ArchiveView"

const Archieve = () => {
    const [data, setData] = useState([]);
    const [text, setText] = useState("");
    const [images, setImages] = useState([]);
    const [viewText, setViewText] = useState('');

    return (
        <>
            <div className='ml-80 mt-1 justify-center text-center'>
                <Search setData={setData} text={text} setText={setText} setImages={setImages} setViewText={setViewText} />
            </div>
            <div>
                <ArchiveView data={data} text={text} images={images} setImages={setImages} viewText={viewText} setViewText={setViewText}  />
            </div>
        </>
    )
}

export default Archieve